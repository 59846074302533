(function () {
  /* HEY. domains hash exists in :
   * site/js/lang_toggle.js
   * site/js/lang_redirect.js
   * THEY MUST STAY THE SAME. chnage one, change the other.
   */

  const domains = {
    // US
    en_US: '',
    fr_US: '/fr-e-NA',
    // UF
    en_UF: '',
    fr_UF: '/fr-e-UF'
  };
  const cookieTools = {
    set(cookieName, value, days) {
      let expires = '';

      if (days) {
        const date = new Date();

        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
      }

      document.cookie = cookieName + '=' + value + expires + '; path=/';
    },
    get(cookieName) {
      const nameEQ = cookieName + '=';
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];

        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1, cookie.length);
        }

        if (cookie.indexOf(nameEQ) === 0) {
          return cookie.substring(nameEQ.length, cookie.length);
        }
      }

      return null;
    },
    remove(cookieName) {
      this.set(cookieName, '', -1);
    }
  };

  function setLocaleFromPrefix(prefix, cookie) {
    const language = prefix.substring(0, 2).toLowerCase();
    const country = prefix.substring(5, 7).toUpperCase();
    const localeFromURL = language + '_' + country;

    if (cookie !== localeFromURL && Object.keys(domains).includes(localeFromURL)) {
      cookieTools.set('LOCALE', localeFromURL);
    }
  }

  function checkLocalePrefix() {
    const path = location.pathname;
    const prefix = path.split('/')[1];
    const localeCookie = cookieTools.get('LOCALE');
    const domainPath = domains[localeCookie];

    // Skip tmpl
    if (path.includes('.tmpl')) {
      return;
    }

    // Set respective locale cookie regarding URL prefix
    if (prefix.match(/..-e-../u)) {
      setLocaleFromPrefix(prefix, localeCookie);

      return;
    }

    /* No cookie or
     * No domain path for the cookie
     */
    if (!domainPath) {
      return;
    }

    // Prefix already set correctly
    if (domainPath.substring(1).toLowerCase() === prefix.toLowerCase()) {
      return;
    }

    const targetPath = location.origin + domainPath + path;
    const destinationURL = location.href.replace(location.origin + path, targetPath);

    // Good to redirect
    location.href = destinationURL;
  }

  checkLocalePrefix();
})();
